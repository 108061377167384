// src/pages/login.tsx
'use client';

import { LoginPopup } from './components/LoginPopup';

export default function LoginPage() {


  return (
       <LoginPopup />


  );
}